<template>
	<div class="footer">
		<div class="wrapper">
			粤IPC 备 19005704号-1 | Copyight©2019 01直播吧.Rights Reserved |
			<router-link to="/aboutus" class="link">关于01直播吧</router-link>
			|
			<router-link to="/disclaimer" class="link">免责申明</router-link>
		</div>
	</div>
</template>

<script>
export default {
	name: 'wsFooter'
};
</script>

<style lang="less"></style>
