<template>
	<div class="wrapper">
		<div class="main-section">
			<div class="content">
				<div class="auchor">
					<img class="avatar" :src="match.face" @error="onAvatarError" />
					<div class="info">
						<div class="title" v-if="match.tournament_id">
							<span class="league">{{  match.league_name_zh  }}</span>
							<span class="time">{{  match.start_time  }}</span>
							<span class="team">
								<span class="teamname" style="text-align: right;">{{  match.home_team_zh  }}</span>
								<img :src="match.home_logo" @error="onTeamLogoError($event, match.type)" />
								<span>VS</span>
								<img :src="match.away_logo" @error="onTeamLogoError($event, match.type)" />
								<span class="teamname">{{  match.away_team_zh  }}</span>
							</span>
						</div>
						<div class="meta">
							<span class="nickname">{{  match.nickname || match.username  }}</span>
							<span class="viewer">{{  match.viewer || match.num || 0 | kindlyNumber  }}</span>
							<span style="flex:1"></span>
						</div>
					</div>
					<div class="extrainfo" v-if="minfo.uid != match.uid">
						<a :class="['follow', match.is_follow == 1 ? 'active' : '']" href="javascript:;"
							@click="onFollow">关注</a>
						<a-popover placement="bottom" v-if="!!match.qrcode">
							<template slot="content">
								<div class="popover-qrcode">
									<img :src="match.qrcode" />
									<span>
										扫一扫
										<br />
										加主播好友
									</span>
								</div>
							</template>
							<img class="qrcode" src="../assets/img/icon-qrcode.png" />
						</a-popover>
					</div>
				</div>
				<div class="player-wrapper">
					<ws-player ref="player" />
           <ad-popup></ad-popup>
				</div>
			</div>
			<div class="aside">
				<ws-chatroom :match="match" :anchor_uid="match.uid" ref="chatroom"></ws-chatroom>
			</div>
		</div>

		<div class="section" v-if="schedules.length > 0">
			<div class="section-header">主播日程</div>
			<div class="section-swiper">
				<div class="schedules">
					<div class="swiper-container">
						<div class="swiper-wrapper">
							<div class="swiper-slide schedule-item" v-for="(item, index) in schedules" :key="index">
								<div class="meta">
									<img v-if="item.tournament_type == 1" src="../assets/img/icon-schedule-1.png" />
									<img v-else-if="item.tournament_type == 2"
										src="../assets/img/icon-schedule-2.png" />
									<span class="league">{{  item.league_name_zh  }}</span>
									{{  (item.start_time_int * 1000) | dateFormat('MM-DD HH:mm')  }}
								</div>
								<div class="teamname">
									<img :src="item.home_logo" @error="onTeamLogoError($event, item.tournament_type)" />
									<span>{{  item.home_team_zh  }}</span>
								</div>
								<div class="teamname">
									<img :src="item.away_logo" @error="onTeamLogoError($event, item.tournament_type)" />
									<span>{{  item.away_team_zh  }}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="section" v-if="recommends.length > 0">
			<div class="section-header">视频推介</div>
			<div class="section-body">
				<ws-match-item v-for="(item, index) in recommends" :key="index" :item="item"></ws-match-item>
			</div>
		</div>

		<div class="tips">
			特别声明：
			<br />
			01直播是一个综合性体育赛事直播平台，我们所有的赛事直播源均由第三方提供，我们不提供任何独家赛事直播视频，不制作任何赛事直播数据。
			<br />
			请各位网友遵守当地国家法律法规，请勿用于非法用途，如有疑问请与我们取得联系。
		</div>
	</div>
</template>

<script>
import wsPlayer from '@/components/wsPlayer.vue';
import wsMatchItem from '@/components/wsMatchItem.vue';
import wsChatroom from '@/components/wsChatroom.vue';
import AdPopup from "@/components/AdPopup";
import { follow } from '@/utils/fn.js';
import { mapState } from 'vuex';
export default {
	name: 'Home',
	components: {
		wsMatchItem,
		wsPlayer,
		wsChatroom,
    AdPopup
	},
	data() {
		return {
			match: {},
			more: [],

			schedules: [],
			recommends: []
		};
	},
	computed: mapState(['minfo']),
	beforeRouteUpdate(to, from, next) {
		this.$loading();
		this.getLiveInfo(to.params);
		next();
	},
	beforeRouteEnter(to, from, next) {
		next(vm => {
			vm.$loading();
			vm.getLiveInfo(to.params);
		});
	},
	created() {
		this.$bus.$on('refresh', () => {
			location.reload();
		});
	},
	methods: {
		async getLiveInfo(route) {
			const { tournament_type, tournament_id, member_id, uid } = route;
			var response = null;
			if (uid) {
				response = await this.$api.get('/web/anchor/live/detail?uid=' + uid);
			} else if (tournament_type && tournament_id && member_id) {
				response = await this.$api.get(`/web/live_lists/${tournament_type}/detail/${tournament_id}?member_id=${member_id}`);
			}
			if (response && response.code == 200) {
				const { more, detail = {} } = response.data;
				detail.code = detail.username;
				detail.username = detail.nickname;
				detail.is_follow = detail.is_follow || 0;
				this.match = detail;
				this.more = (more || [])
					.filter(v => v.member_id != detail.member_id)
					.map(item => {
						return this.makeMatchItem(Object.assign({}, detail, item));
					});
				this.$nextTick(() => {
					this.$refs.player.initPlayer(detail);
					this.$refs.chatroom.init(detail.roomid);
				})
				if (member_id || detail.member_id) {
					this.getAnchorSchedule(member_id || detail.member_id);
				}
			}

			this.getLives();
			this.$nextTick(() => {
				this.$loadend();
			});
		},
		async getAnchorSchedule(member_id) {
			const response = await this.$api.get(`/web/anchor_schedule`, {
				params: { member_id }
			});
			if (response && response.code == 200) {
				this.schedules = response.data;
			}
		},
		async onFollow() {
			if (this.match.is_follow == 1) {
				this.$modal.confirm({
					title: '取消关注后，将不会收到主播开播提醒，确定取消吗？',
					okType: 'danger',
					okText: '取消关注',
					cancelText: '我再想想',
					onOk: () => {
						follow(this.match);
					}
				});
			} else {
				follow(this.match);
			}
		},
		async getLives() {
			const response = await this.$api.get(`/web/live_lists/1`);
			if (response && response.code == 200) {
				this.recommends = response.data.data.map(this.makeMatchItem);
			}
			this.$nextTick(() => {
				this.$loadend();
			});
		}
	}
};
</script>

<style lang="less">
@import url('~@/assets/less/live.less');

.popover-qrcode {
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: 12px;
	color: #333333;
	text-align: center;

	img {
		width: 84px;
		height: 84px;
		margin-bottom: 10px;
	}
}
</style>
