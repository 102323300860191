<template>
	<div class="form-group form-password">
		<input type="text" class="input" :placeholder="placeholder || '请输入密码'" v-model="currentValue" v-if="showPassword" />
		<input type="password" class="input" :placeholder="placeholder || '请输入密码'" v-model="currentValue" v-else />
		<img class="icon" src="../assets/img/icon-eye-open.png" v-if="showPassword" @click="showPassword = false" />
		<img class="icon" src="../assets/img/icon-eye.png" v-else @click="showPassword = true" />
	</div>
</template>

<script>
export default {
	props: {
		value: String,
		placeholder: String
	},
	data() {
		return {
			showPassword: false
		};
	},
	computed: {
		currentValue: {
			get(val) {
				return this.value || '';
			},
			set(val) {
				this.$emit('input', val);
			}
		}
	}
};
</script>

<style lang="less">
.form-group.form-password .icon {
	width: 18px;
	height: 18px;
	object-fit: contain;
}
</style>
