<template>
	<div class="wrapper">
		<div class="ucenter">
			<aside>
				<div class="aside-header">个人中心</div>
				<nav>
					<router-link to="/user/info" :class="{ active: routeName == 'UserInfo' }">
						<span class="icon icon-center"></span>
						我的资料
					</router-link>
					<router-link to="/user/follow" :class="{ active: routeName == 'UserFollow' }">
						<span class="icon icon-follow"></span>
						我的关注
					</router-link>
					<router-link to="/user/appointment" :class="{ active: routeName == 'UserAppointment' }">
						<span class="icon icon-appointment"></span>
						我的预约
					</router-link>
					<router-link to="/user/feedback" :class="{ active: routeName == 'UserFeedback' || routeName == 'UserFeedbackCreate' }">
						<span class="icon icon-feedback"></span>
						我的反馈
					</router-link>
				</nav>
			</aside>
			<main>
				<div class="main-header">
					<span class="title">{{ $route.meta.title }}</span>
					<router-link v-if="routeName == 'UserFeedback'" class="button" to="/user/feedback/create">去 反 馈</router-link>
				</div>
				<router-view class="main-body"></router-view>
			</main>
		</div>
	</div>
</template>

<script>
export default {
	name: 'User',
	computed: {
		routeName() {
			return this.$route.name;
		}
	},
	mounted() {
		if (!this.$storage.get('token')) {
			location.href = '/';
		}
		this.$loadend();
	}
};
</script>

<style lang="less">
@import url('~@/assets/less/ucenter.less');
</style>
