<template>
	<div class="wrapper">
		<div class="main-section">
			<div class="content">
				<div class="auchor">
					<img class="avatar" :src="match.face" @error="onAvatarError" />
					<div class="info">
						<div class="title">{{ match.title }}</div>
						<div class="meta">
							<span class="nickname">{{ match.nickname || match.username }}</span>
						</div>
					</div>
				</div>
				<div class="player-wrapper"><ws-player ref="player" /></div>
			</div>
			<div class="aside"><ws-chatroom :match="match" :anchor_uid="match.roomid" ref="chatroom"></ws-chatroom></div>
		</div>

		<div class="section" v-if="recommends.length > 0">
			<div class="section-header">视频推介</div>
			<div class="section-body"><ws-match-item v-for="(item, index) in recommends" :key="index" :item="item"></ws-match-item></div>
		</div>

		<div class="tips">
			特别声明：
			<br />
			01直播是一个综合性体育赛事直播平台，我们所有的赛事直播源均由第三方提供，我们不提供任何独家赛事直播视频，不制作任何赛事直播数据。
			<br />
			请各位网友遵守当地国家法律法规，请勿用于非法用途，如有疑问请与我们取得联系。
		</div>
	</div>
</template>

<script>
import wsPlayer from '@/components/wsPlayer.vue';
import wsMatchItem from '@/components/wsMatchItem.vue';
import wsChatroom from '@/components/wsChatroom.vue';
export default {
	name: 'Home',
	components: {
		wsMatchItem,
		wsPlayer,
		wsChatroom
	},
	data() {
		return {
			match: {},
			more: [],
			recommends: []
		};
	},
	beforeRouteUpdate(to, from, next) {
		this.$loading();
		this.getReplyInfo(to.params);
		next();
	},
	created() {
		this.$loading();
		this.getReplyInfo(this.$route.params);
	},
	methods: {
		async getReplyInfo(route) {
			const { id } = route;
			const response = await this.$api.get(`/web/video_list/detail/${id}`);
			if (response && response.code == 200) {
				const { more, detail } = response.data;
				this.match = this.makeMatchItem(detail || {});
				this.more = (more || [])
					.filter(v => v.member_id != detail.member_id)
					.map(item => {
						return this.makeMatchItem(Object.assign({}, detail, item));
					});
				this.$nextTick(() => {
					this.$refs.player.initPlayer(detail);
					this.$refs.chatroom.init(detail.roomid);
				});
			}
			this.getLives();
			this.$nextTick(() => {
				this.$loadend();
			});
		},
		async getLives() {
			const response = await this.$api.get(`/web/live_lists/1`);
			if (response && response.code == 200) {
				this.recommends = response.data.data.map(this.makeMatchItem);
			}
			this.$nextTick(() => {
				this.$loadend();
			});
		}
	}
};
</script>

<style lang="less">
@import url('~@/assets/less/live.less');
</style>
