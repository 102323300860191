<template>
    <div class="down_popup" v-show="codeShow">
        <div class="popup_box">
            <img class="del" src="../assets/img/del.png" alt="" @click="codeShow = false">
            <img class="qrcode" src="../assets/img/download-178qrcode.png?v5" alt="" />
        </div>
    </div>
</template>

<script>
function setCookie(name,val,time){
    var now=new Date();
    // now.setMinutes(now.getHours()+time)
    now.setHours(now.getHours()+time)
    document.cookie=name+'='+val+';expires='+now.toUTCString()+';path=/'
}
function getCookie(cookieName) {

    var search = cookieName + "="
    var returnValue = "";
    if (document.cookie.length > 0) {
        let offset = document.cookie.indexOf(search)
        if (offset != -1) {
            offset += search.length
            let end = document.cookie.indexOf(";", offset);
            if (end == -1)
                end = document.cookie.length;
            returnValue = unescape(document.cookie.substring(offset, end));
        }
    }
    return returnValue;
}
export default {
    name: "adPopup",
    data(){
        return{
            codeShow:false
        }
    },
    mounted() {
        let isCode = getCookie('codeShow')
        console.log(isCode);
        if(!isCode){
            setTimeout(()=>{
                this.codeShow = true
                setCookie("codeShow","true",24)
            },30000)
        }
    }
}
</script>

<style lang="less" scoped>
.down_popup{
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(27,27,27,0.8);
    left: 0px;
    top: 0px;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
.popup_box{
    width: 380px;
    height: 340px;
    position: relative;
    background: url("../assets/img/code_bg.png");
.qrcode{
    width: 180px;
    height:180px;
    position: absolute;
    top: 63px;
    left: 109px;
}
.del{
    position: absolute;
    right: 0;
    top: -20px;
    cursor: pointer;
}
}

}
</style>
